import React, { useState, useEffect } from "react"
import { Box, Heading } from "@chakra-ui/core"

type Props = {}

export const FixedSidebar: React.FC<Props> = ({ children }) => {
  const [position, setPosition] = useState(false)

  const listenScrollEvent = (e: any) => {
    if (window.scrollY > 390) {
      setPosition(true)

    }
    if (window.scrollY < 390) {

      setPosition(false)
    }
  }

  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("scroll", listenScrollEvent)
  })
  return (
    <Box
      display="flex"
      flexDirection="column"
      text-align="left"
      position={
        position ? ["unset", "unset", "unset", "unset", "fixed"] : "relative"
      }
      top="0px"
      fontSize="1rem"
      overflowY="auto"
      height="100%"
    >
      {children}
    </Box>
  )
}
